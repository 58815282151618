import React, { useState } from "react";
import "./App.css";
import { TopicContext } from "./components/TopicContext";
import Carousel from "./components/Carousel";
import { NavBar } from "./components/NavBar";
import SplashScreen from "./components/SplashScreen";

const App = () => {
  const [carouselIndex, setCarouselIndex] = useState(1);
  const [showSplash, setShowSplash] = useState(true);

  return (
    <TopicContext.Provider
      value={{
        carouselIndex,
        setCarouselIndex,
        showSplash,
        setShowSplash,
      }}
    >
      <div className="App">
        <SplashScreen />
        <NavBar />
        <Carousel />
      </div>
    </TopicContext.Provider>
  );
};

export default App;
